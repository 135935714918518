import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IProfile, IGenderPickerOptions } from '../../types';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  profile: IProfile;
  genderPicker: IGenderPickerOptions;
  genderPrefPicker: IGenderPickerOptions;

  constructor(
    private router: Router,
    private dataService: DataService
  ) {
    this.profile = this.dataService.getProfile();
    this.genderPicker = {
      onSelect: (option) => { this.genderPicker.selectedOption = option },
      id: "gender",
      selectedOption: { index: 1 }
    };
    this.genderPrefPicker = {
      onSelect: (option) => this.genderPrefPicker.selectedOption = option,
      id: "gender-preference",
      selectedOption: { index: 0 }
    };
  }

  ngOnInit(): void { }

  toHome() {
    this.router.navigate(['home']);
  }
}
