import { Component, OnInit, Input } from '@angular/core';
import { ILocationOption, ILocationPickerOptions } from '../../types';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'app-locationpicker',
  templateUrl: './locationpicker.component.html',
  styleUrls: ['./locationpicker.component.css']
})
export class LocationpickerComponent implements OnInit {
  @Input() picker: ILocationPickerOptions;

  constructor(public dataService: DataService) { }

  ngOnInit(): void {
    this.picker.options = this.dataService.getLocations();
  }
}
