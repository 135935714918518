import { Component } from '@angular/core';
import { RouterOutlet, Router, NavigationStart } from '@angular/router';
import { slideInAnimation } from './animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    // animation triggers go here
    slideInAnimation
  ]
})
export class AppComponent {
  title = 'hair-salon-app';

  constructor(private router: Router) {
    // this.router.events.subscribe(routerEvent => {
    //   if (routerEvent instanceof NavigationStart) {
    //     if (routerEvent.url == "/") {
    //       this.router.navigate(["products"], { skipLocationChange: true })
    //     }
    //   }
    // });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

}


