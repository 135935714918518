import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDatePickerOptions, IServiceForm, IDateOption, ITimeOption, ILocationOption, IServiceOption, IDurationOption } from '../../types';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'app-service-filter',
  templateUrl: './service-filter.component.html',
  styleUrls: ['./service-filter.component.css']
})
export class ServiceFilterComponent implements OnInit {
  serviceOptions: IServiceOption[] = null;
  serviceForm: IServiceForm = null;

  constructor(
    private router: Router,
    private dataService: DataService
  ) {
    this.serviceOptions = this.dataService.getServices();
    this.setFormDefaults();
  }

  ngOnInit(): void { }

  toHome() {
    this.router.navigate(['home']);
  }

  goToFilterResults() {
    this.router.navigate(['home']);
  }

  setFormDefaults() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    this.serviceForm = {
      datePickerOptions: {
        id: "date-picker",
        startDate: tomorrow,
        daysCount: 9,
        label: "Vanaf",
        onSelect: (option: IDateOption) => {
          this.serviceForm.datePickerOptions.selectedOption = option;
          this.dataService.scrollToOption(this.serviceForm.datePickerOptions.id, option.index);
          this.setMinimumPrice();
        },
      },
      timePickerOptions: {
        id: "time-picker",
        showAmPm: true,
        hourCount: 24,
        timeStep: 15,
        label: "Tijd",
        startTime: {
          hour: 10,
          minute: 0
        },
        onSelect: (option: ITimeOption) => {
          this.serviceForm.timePickerOptions.selectedOption = option;
          this.dataService.scrollToOption(this.serviceForm.timePickerOptions.id, option.index);
          this.setMinimumPrice();
        },
      },
      durationPickerOptions: {
        id: "duration-picker",
        onSelect: (option: IDurationOption) => {
          this.serviceForm.durationPickerOptions.selectedOption = option;
          this.dataService.scrollToOption(this.serviceForm.durationPickerOptions.id, option.index);
          this.setMinimumPrice();
        }
      },
      locationPickerOptions: {
        id: "location-picker",
        onSelect: (option: ILocationOption) => {
          this.serviceForm.locationPickerOptions.selectedOption = option;
          this.setMinimumPrice();
        }
      },
      servicePickerOptions: {
        id: "service-picker",
        onSelect: (option: IServiceOption) => {
          this.serviceForm.servicePickerOptions.selectedOption = option;
          this.setMinimumPrice();
        }
      },
      pricePickerOptions: {
        minValue: 50,
        maxValue: 2000,
        step: 10,
        value: 50
      }
    }
  }

  setMinimumPrice(): void {
    let startPrice: number = this.serviceForm.servicePickerOptions.selectedOption.basePrice;
    let totalPrice: number = this.serviceForm.servicePickerOptions.selectedOption.basePrice;

    totalPrice += this.getPricePerOption("date", startPrice, this.serviceForm.datePickerOptions.selectedOption);
    totalPrice += this.getPricePerOption("time", startPrice, this.serviceForm.timePickerOptions.selectedOption);
    totalPrice += this.getPricePerOption("location", startPrice, this.serviceForm.locationPickerOptions.selectedOption);

    console.log("totalPrice: ", totalPrice);
    this.serviceForm.pricePickerOptions.value = totalPrice;
    this.serviceForm.pricePickerOptions.minValue = totalPrice;
  }

  getPricePerOption(label: string, basePrice: number, option: any): number {
    if (!option)
      return 0;

    const optionPriceImpact = option.price.impact || 1;
    const marginOnOption = Math.round(optionPriceImpact * basePrice * this.serviceForm.pricePickerOptions.step);
    const marginOnOptionRound = Math.round(marginOnOption - (marginOnOption % this.serviceForm.pricePickerOptions.step));
    console.log(`price for option ${label} : ${marginOnOptionRound}`);
    return marginOnOptionRound;
  }
}
