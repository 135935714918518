import { Component, OnInit, Input } from '@angular/core';
import { IGenderPickerOptions } from '../../types';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'app-genderpicker',
  templateUrl: './genderpicker.component.html',
  styleUrls: ['./genderpicker.component.css']
})
export class GenderpickerComponent implements OnInit {
  @Input() picker: IGenderPickerOptions;

  constructor(
    public dataService: DataService
  ) { }

  ngOnInit(): void {
    this.picker.options = this.dataService.getGenders();
  }
}
