import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { ServiceFilterComponent } from './components/service-filter/service-filter.component';
import { SettingsComponent } from './components/settings/settings.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, data: {} },
  // { path: 'product-detail', component: ProductDetailComponent, data: { animation: 'DetailPage' } },
  { path: 'filter', component: ServiceFilterComponent, data: { animation: 'isRight' } },
  { path: 'settings', component: SettingsComponent, data: { animation: 'isLeft' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
