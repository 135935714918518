import { Component, OnInit, Input } from '@angular/core';
import { IOffer } from '../../types';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {
  @Input() offer: IOffer;

  constructor() { }

  ngOnInit(): void {
  }

}
