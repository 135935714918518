import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { ServiceFilterComponent } from './components/service-filter/service-filter.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import { LocationpickerComponent } from './components/locationpicker/locationpicker.component';
import { ServicepickerComponent } from './components/servicepicker/servicepicker.component';
import { DataService } from './services/data/data.service';
import { DurationpickerComponent } from './components/durationpicker/durationpicker.component';
import { OfferComponent } from './components/offer/offer.component';
import { OffersComponent } from './components/offers/offers.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TextpickerComponent } from './components/textpicker/textpicker.component';
import { ImagepickerComponent } from './components/imagepicker/imagepicker.component';
import { GenderpickerComponent } from './components/genderpicker/genderpicker.component';

registerLocaleData(localeNl);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductDetailComponent,
    ServiceFilterComponent,
    DatepickerComponent,
    TimepickerComponent,
    LocationpickerComponent,
    ServicepickerComponent,
    DurationpickerComponent,
    OfferComponent,
    OffersComponent,
    SettingsComponent,
    TextpickerComponent,
    ImagepickerComponent,
    GenderpickerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
