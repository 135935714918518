import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  isFilterScreen = false;
  modeParamSubscription;

  ngOnInit(): void {

    this.modeParamSubscription = this.route.queryParamMap.subscribe(p => {
      this.isFilterScreen = p.get('isFilterScreen') == 'true' ? true : false;
    });

  }

  ngOnDestroy() {

    if (this.modeParamSubscription) {
      this.modeParamSubscription.unsubscribe();
    }

  }

  back() {
    if (this.isFilterScreen)
      this.router.navigate(['filter'], { skipLocationChange: true });
    else
      this.router.navigate(['products'], { skipLocationChange: true });
  }

}
