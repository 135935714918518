import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ITimePickerOptions, ITimeOption, IDateOption } from '../../types';
import * as _ from 'underscore';
import { ConfigService } from '../../services/config/config.service';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.css']
})
export class TimepickerComponent implements OnInit {
  @Input() picker: ITimePickerOptions;

  constructor(
    public dataService: DataService
  ) { }

  ngOnInit(): void {
    this.getTimes();
  }

  ngOnChanges(): void { }

  getTimes(): void {
    this.picker.options = this.dataService.getTimes(
      this.picker.startTime?.minute,
      this.picker.timeStep,
      this.picker.hourCount,
      true
    );
  }
}
