import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { IOffer } from '../../types';
import { trigger, transition, query, style, animate, group } from '@angular/animations';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css'],
  animations: [
    trigger('offerAnimation', [
      transition('* => intro', [
        query('.cards-wrapper li', [
          style({ transform: 'scale(0.5) translateX(300%)', opacity: 0.5 })
        ]),
        group([
          query('.cards-wrapper li:nth-child(1)', animate(2000, style({ transform: 'scale(0.6) translateX(65%)', opacity: 1 }))),
          query('.cards-wrapper li:nth-child(2)', animate(1700, style({ transform: 'scale(0.7) translateX(43%)', opacity: 1 }))),
          query('.cards-wrapper li:nth-child(3)', animate(1400, style({ transform: 'scale(0.8) translateX(26%)', opacity: 1 }))),
          query('.cards-wrapper li:nth-child(4)', animate(1100, style({ transform: 'scale(0.9) translateX(12%)', opacity: 1 }))),
          query('.cards-wrapper li:nth-child(5)', animate(800, style({ transform: 'scale(1) translateX(0px)', opacity: 1 })))
        ])
      ])
    ])
  ]
})
export class OffersComponent implements OnInit {
  offers: IOffer[] = [];
  ani: any = null;

  constructor(
    private dataService: DataService
  ) {
    this.offers = this.dataService.getOffers()
      .sort(x => x.index)
      .reverse();
  }

  ngOnInit(): void {
    this.ani = "intro"
  }
}
