import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDateOption, IDatePickerOptions } from '../../types';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css']
})
export class DatepickerComponent implements OnInit {
  @Input() picker: IDatePickerOptions;

  constructor(public dataService: DataService) { }

  ngOnInit(): void {
    this.picker.options = this.dataService.getDates(this.picker.startDate, this.picker.daysCount);
  }
}