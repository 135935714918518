import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  defaultHourMarge = 2;
  impossibleTimeOptions = [0, 1, 2, 3, 4, 5, 6, 23];
}
