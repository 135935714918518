import { Component, OnInit, Input } from '@angular/core';
import { IServicePickerOptions } from '../../types';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'app-servicepicker',
  templateUrl: './servicepicker.component.html',
  styleUrls: ['./servicepicker.component.css']
})
export class ServicepickerComponent implements OnInit {
  @Input() picker: IServicePickerOptions;

  constructor(public dataService: DataService) { }

  ngOnInit(): void {
    this.picker.options = this.dataService.getServices();
    this.picker.selectedOption = this.picker.options[2];
  }
}
