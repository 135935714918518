import { Component, OnInit, Input } from '@angular/core';
import { IImage } from '../../types';

@Component({
  selector: 'app-imagepicker',
  templateUrl: './imagepicker.component.html',
  styleUrls: ['./imagepicker.component.css']
})
export class ImagepickerComponent implements OnInit {
  @Input() images: IImage[];

  constructor() { }

  ngOnInit(): void { }

  select(selected: IImage) {
    let first = this.images[0];

    first.index = selected.index;
    selected.index = 0;

    this.images.sort((a, b) => {
      return a.index - b.index;
    });
  }

  triggerUpload() {
    document.getElementById("uploader").click();
  }

  uploadFile($event) {
    console.log($event.target.files[0]); // outputs the first file
  }

}
