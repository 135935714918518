import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-textpicker',
  templateUrl: './textpicker.component.html',
  styleUrls: ['./textpicker.component.css']
})
export class TextpickerComponent implements OnInit {
  @Input() textInput;

  constructor() { }

  ngOnInit(): void { }

}
