import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { IDurationPickerOptions, IDurationOption } from '../../types';

@Component({
  selector: 'app-durationpicker',
  templateUrl: './durationpicker.component.html',
  styleUrls: ['./durationpicker.component.css']
})
export class DurationpickerComponent implements OnInit {
  @Input() picker: IDurationPickerOptions;

  constructor(
    public dataService: DataService
  ) { }

  ngOnInit(): void {
    this.picker.options = this.dataService.getDurations();
  }
}
