import { Injectable } from '@angular/core';
import { IServiceOption, IDateOption, ILocationOption, ITimePickerOptions, ITimeOption, IPickerOptions, IDurationOption, IOffer, IProfile, IGenderOption } from '../../types';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(public configService: ConfigService) { }

  getDates(startDate: Date = new Date(), dayCount: number = 9): IDateOption[] {
    let dateOptions = [];
    const tempDate = new Date();
    for (var i = 0; i < dayCount; i++) {
      tempDate.setDate(startDate.getDate() + i);
      dateOptions.push({
        index: i,
        date: new Date(tempDate.getTime()),
        disabled: i == 0,
        price: {
          impact: Math.random()
        }
      });
    }
    return dateOptions;
  }

  getServices(): IServiceOption[] {
    return [
      {
        name: 'Sauna',
        icon: 'hot_tub',
        id: 1,
        basePrice: 100,
      },
      {
        name: 'Cuddle',
        icon: 'people',
        id: 2,
        basePrice: 50
      },
      {
        name: 'Sport',
        icon: 'directions_bike',
        id: 3,
        basePrice: 10
      },
    ]
  }

  getLocations(): ILocationOption[] {
    return [
      {
        id: 1,
        name: 'My place',
        icon: 'home',
        info: 'your address will not be shown untill you explicitly give it after a match is made!',
        mapVisible: false,
        price: {
          impact: Math.random()
        }
      },
      {
        id: 2,
        name: 'Your place',
        icon: 'explore',
        info: 'his/her address will not be shown untill he/she explicitly gives it after a match is made!',
        mapVisible: false,
        price: {
          impact: Math.random()
        }
      },
      {
        id: 3,
        name: 'Another place',
        icon: 'business',
        mapVisible: true,
        disabled: true,
        price: {
          impact: Math.random()
        }
      },
    ];
  }

  getTimes(
    //startHour: number = new Date().getHours() + this.configService.defaultHourMarge,
    startMinutes: number = 0,
    timeStep: number = 30,
    hourCount: number,
    showAmPm: boolean = true): ITimeOption[] {

    const timeOptions: ITimeOption[] = [];
    let index = 0;

    for (var i = 0; i < hourCount; i++) {
      for (var j = startMinutes; j < 60; j += timeStep) {
        if (this.configService.impossibleTimeOptions.indexOf(i) < 0) {
          timeOptions.push({
            hour: (showAmPm && i > 12) ? i - 12 : i,
            am: i < 12,
            minute: j,
            index: index,
            disabled: false, // this.isDisabledTimeOption(selectedDate, i, startHour),
            price: {
              impact: Math.random()
            }
          });
          index++;
        }
      }
    }
    return timeOptions;
  }

  getDurations(): IDurationOption[] {
    return [
      {
        index: 0,
        hourCount: 0.5
      }, {
        index: 1,
        hourCount: 1
      }, {
        index: 2,
        hourCount: 2
      }, {
        index: 3,
        hourCount: 4
      }, {
        index: 4,
        hourCount: 8
      }, {
        index: 5,
        hourCount: 12
      }, {
        index: 6,
        hourCount: 24
      },

    ];
  }

  getOffers(): IOffer[] {
    return [{
      index: 0,
      price: 99,
      service: "Sport",
      date: "18/06",
      time: "10:30",
      duration: "1h",
      image: "https://images.unsplash.com/photo-1578297925104-a49c7828a20d?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
      quote: "Let's do some sport"
    }, {
      index: 1,
      price: 250,
      service: "Sauna",
      date: "19/06",
      time: "14:30",
      duration: "1h",
      image: "https://images.unsplash.com/photo-1592242481757-a0519c3d0d89?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1868&q=80",
      quote: "Let's have some fun!"
    }, {
      index: 2,
      price: 750,
      service: "Cinema",
      date: "20/06",
      time: "20:00",
      duration: "1h",
      image: "https://images.unsplash.com/photo-1568153877945-1b3ca406f0d5?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
      quote: "Let's have some fun!"
    }, {
      index: 3,
      price: 260,
      service: "Sport",
      date: "20/06",
      time: "20:30",
      duration: "1h",
      image: "https://images.unsplash.com/photo-1574781852878-ca9622f6ac32?ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80",
      quote: "Let's have some fun!"
    }, {
      index: 4,
      price: 400,
      service: "Sport",
      date: "19/06",
      time: "20:30",
      duration: "1h",
      image: "https://images.unsplash.com/photo-1561731885-e0591a34659c?ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80",
      quote: "Looking for company!"
    }]
  }

  getProfile(): IProfile {
    return {
      displayName: "RobinG",
      fullName: "Robin Geraerts",
      gender: "M",
      genderPreference: "F",
      images: [{
        path: "https://images.unsplash.com/photo-1537368910025-700350fe46c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        index: 0
      }, {
        path: "https://images.unsplash.com/photo-1563986768626-956041a8bf9d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1977&q=80",
        index: 1
      }, {
        path: "https://images.unsplash.com/photo-1559526323-cb2f2fe2591b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        index: 2
      }, {
        path: "https://images.unsplash.com/photo-1556157382-97eda2d62296?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
        index: 3
      }],
      distanceRange: 15,
      location: {
        x: 10.2233,
        y: 40.83349,
        lastUpdated: new Date()
      }
    };
  }

  getGenders(): IGenderOption[] {
    return [
      {
        name: "Female",
        value: "F",
        icon: "wc",
        index: 0
      },
      {
        name: "Male",
        value: "M",
        icon: "wc",
        index: 1
      },
      {
        name: "Uni",
        value: "X",
        icon: "wc",
        index: 2
      },
    ];
  }
  /////////////////////////////

  isDisabledTimeOption(selectedDate, i, startHour): boolean {
    if (selectedDate.getUTCDate() == new Date().getUTCDate() && i < startHour)
      return true;
    return false;
  }

  scrollToOption(pickerId: string, index: number): void {
    const left = 60 * (index - 2);
    setTimeout(function () {
      document.querySelector("#" + pickerId).scrollLeft = left;
    }, 300);
  }
}
